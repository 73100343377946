import { Cart, SampleBoxUUID } from 'mycs/api/CheckoutAPI';
import cfg from 'mycs/config';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import {
  AddToCartTagInfo,
  CheckoutAnalyticsService,
  CheckoutEventTagInfo,
  OrderSucessEventTagInfo,
  ProductCartTagInfo,
} from './CheckoutAnalyticsService';
import EcondaAnalyticsService from 'mycs/shared/services/EcondaAnalyticsService';
import {
  getCurrPriceWithoutAssembly,
  toPriceInMajorUnits,
} from 'mycs/services/CartService';

export const CheckoutEventsService = {
  sendNewEvent(
    stepID: number,
    optionName: string,
    cart: Cart,
    locationPathname: string,
    locale: string,
    countryCode: string
  ) {
    const tagInfo: CheckoutEventTagInfo = {
      locationPathname,
      countryCode: countryCode,
      currencyCode: cart.currency,
      products: getProductsInEcommerceFormat(cart, locale),
      quantity: (cart.lineItems || []).reduce(
        (sum, { quantity }) => sum + quantity,
        0
      ),
      total: toPriceInMajorUnits(
        (cart.lineItems || []).reduce(
          (sum, { price, quantity }) => sum + price * quantity,
          0
        )
      ),
      orderTotal: toPriceInMajorUnits(cart.total).toString(),
      discountTotal: toPriceInMajorUnits(cart.couponDiscount).toString(),
      taxTotal: toPriceInMajorUnits(cart.taxes).toString(),
      shippingCost: toPriceInMajorUnits(cart.shippingCost).toString(),
      couponCode: cart.couponCode,
      coupon: cart.coupon,
      transaction_id: cart.id,
    };

    CheckoutAnalyticsService.trackCheckoutEvent(
      stepID,
      optionName,
      tagInfo,
      locale
    );
  },
  sendOrderSuccessEvent(order: Cart, locationPathname: string, locale: string) {
    // Avoid test and PR orders tracking on GA
    const isMaterialsSamplePurchase = order.lineItems?.every(
      (item) => item.uuid === SampleBoxUUID
    ) ?? false;
    
    const orderSucessTagInfo: OrderSucessEventTagInfo = {
      locationPathname,
      countryCode: order.countryCode,
      currencyCode: order.currency,
      id: order.id,
      products: getProductsInEcommerceFormat(order, locale),
      tax: toPriceInMajorUnits(order.taxes).toString(),
      shipping: toPriceInMajorUnits(order.shippingCost).toString(),
      revenue: toPriceInMajorUnits(order.total).toString(),
      couponCode: order.couponCode || '',
    };

    CheckoutAnalyticsService.trackOrderSuccesEvent(
      isMaterialsSamplePurchase,
      orderSucessTagInfo,
      locale
    );
    CheckoutAnalyticsService.sendWelcomeEmail(locale);

    const shouldSendEcondaEvent = cfg.environment !== 'dev' && order.total > 0;

    if (shouldSendEcondaEvent) {
      EcondaAnalyticsService.trackOrder(
        order.id,
        toPriceInMajorUnits(order.total)
      );
    }
  },
  sendProductCartEvent(cart: Cart | undefined, locale: string) {
    const productCartTagInfo: ProductCartTagInfo = {
      products: (cart?.lineItems || []).map((item) => {
        // GA gets single item price.
        const price =
          toPriceInMajorUnits(getCurrPriceWithoutAssembly(item)) /
          item.quantity;

        return {
          category: item.type,
          name: I18nUtils.localize(locale, item.title),
          currency: cart?.currency || '',
          id: item.uuid,
          image: item.meta.imageURL || '',
          url: item.meta.productDetailsPageURL || '',
          price,
          quantity: item.quantity,
        };
      }),
      total: cart ? toPriceInMajorUnits(cart.total) : 0,
    };

    CheckoutAnalyticsService.trackProductCartEvent(locale, productCartTagInfo);
  },
  sendAddToCartEvent(
    cart: undefined | Cart,
    addedItemIndex: number,
    addedQuantity: number,
    locale: string,
    countryCode: string
  ) {
    const lineItem = cart?.lineItems?.[addedItemIndex];
    if (!lineItem) {
      throw Error('index out of range');
    }

    // GA gets single item price.
    const price =
      toPriceInMajorUnits(getCurrPriceWithoutAssembly(lineItem)) /
      lineItem.quantity;

    const addToCartTagInfo: AddToCartTagInfo = {
      countryCode: countryCode,
      currencyCode: cart.currency,
      products: [
        {
          id: lineItem.uuid,
          name: I18nUtils.localize(locale, lineItem.title),
          price,
          brand: '',
          category: lineItem.type,
          variant: '',
          quantity: addedQuantity,
        },
      ],
    };

    CheckoutAnalyticsService.trackAddToCartEvent(addToCartTagInfo, locale);
  },
};

function getProductsInEcommerceFormat(cart: Cart, locale: string) {
  return (cart.lineItems || []).map((lineItem) => {
    // GA gets single item price.
    const price =
      toPriceInMajorUnits(getCurrPriceWithoutAssembly(lineItem)) /
      lineItem.quantity;

    return {
      name: I18nUtils.localize(locale, lineItem.title),
      id: lineItem.uuid,
      price: price.toString(),
      brand: '',
      category: lineItem.type, //furnitureType,
      variant: '',
      quantity: lineItem.quantity,
      cogs: 0,
      logistics: 0,
      payment_rate: 0,
    };
  });
}
